import React, { useState, Fragment, useEffect, useRef, useMemo } from "react";
import { Form, Modal, Tabs, Typography, Input, Table, Select, Checkbox, Upload, Button, Row, notification, message } from "antd";
import { PlusOutlined, AudioOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import { getsearchFieldData, getAutoCompleteData, getProcessParamComboFill, getFilesData } from "../../services/generic";
import { DatePicker, TimePicker } from "../../lib/date";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FieldReference } from "../../lib/fieldReference";
import { useGlobalContext, useWindowContext } from "../../lib/storage";
import Close from "../../assets/images/close-x.svg";
import useDebounce from "../../lib/hooks/useDebounce";
import { useParams } from "react-router";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import dayjs from "dayjs";
import ReactQuill, { Quill } from "react-quill";
import axios from "axios";
import ThemeJson from "../../constants/UIServer.json"
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import "../../styles/antd.css";
import "./index.css"

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;
const { TextArea, Password } = Input;

const FormField = (props) => {
  const { windowId } = useParams();
  const { globalStore } = useGlobalContext();
  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const parentTabData = { ...windowStore.windowHeaderTabRecords };
  const { recordId } = useParams();
  const Themes = ThemeJson;
  const userPreferences = globalStore.userPreferences;
  const { field, fieldData, form, headerTabId, dataOptions, setDataOptions, jsonParam, parentId, headerTab, setLoading, formFieldIndexKey, autoCompleteHiddenData, setAutoCompleteHiddenData, fields,setIsEditMode } = props;

  const getFieldValue = () => {
    const fieldValueIden = fieldData[field.ad_field_id.concat("_iden")];
    if (fieldValueIden) {
      return fieldValueIden;
    } else {
      // form.setFieldsValue({ [field.ad_field_id]:  fieldData[field.ad_field_id] });
      return fieldData[field.ad_field_id];
    }
  };

  const initialOptionsForTagsList = () => {
    const keys = getFieldValue(); // Get the field value, which might be a stringified JSON or an array
    const parsedValue = typeof keys === 'string' ? JSON.parse(keys) : keys; // Parse only if it's a string

    // Check if the parsed value is an array and proceed
    if (Array.isArray(parsedValue)) {
      const valuesArray = parsedValue
        .map(opt => {
          // For each item in parsedValue, find the matching entry in field.Values by key
          const listElement = field.Values.find(res => res.key === opt);
          // Return an object with id and value if listElement exists
          return listElement ? { key: opt, value: listElement.value } : null;
        })
        .filter(Boolean); // Filter out any null values in case of unmatched keys

      return valuesArray; // Return the array of objects as the initial options for tags list
    } else {
      console.warn("Parsed value is not an array:", parsedValue);
      return [];
    }
  };

  let initialOptionsValue = [];
  if (getFieldValue()) {
    initialOptionsValue = [{ RecordID: fieldData[field.ad_field_id], Name: getFieldValue() }];
  }

  let initialOptionsValueForSelector = [];
  if (getFieldValue()) {
    initialOptionsValueForSelector = [{ recordid: fieldData[field.ad_field_id], name: getFieldValue() }];
  }

  let initialOptionsTags = [];
  const fieldValue = getFieldValue();

  if (fieldValue) {
    try {
      // Check if `fieldValue` is a JSON string and parse it if necessary
      const parsedFieldValue = typeof fieldValue === 'string' ? JSON.parse(fieldValue) : fieldValue;

      // If `parsedFieldValue` is an array, map it to create the desired structure
      if (Array.isArray(parsedFieldValue)) {
        initialOptionsTags = parsedFieldValue.map(item => ({
          RecordID: item.id,
          Name: item.iden
        }));
      } else {
        // If not an array, handle it as a single item
        initialOptionsTags = [{ RecordID: fieldData[field.ad_field_id], Name: fieldValue }];
      }
    } catch (error) {
      // console.error("Error parsing fieldValue:", error);
    }
  }

  const [options, setOptions] = useState(initialOptionsValue);
  const [optionsForSelector, setOptionsForSelector] = useState(initialOptionsValueForSelector);
  const [optionsForTags, setOptionsForTags] = useState(initialOptionsTags)
  const getCompactFieldValue = () => {
    const compactFieldValueIden = fieldData[field.compact_field_id?.concat("_iden")];
    if (compactFieldValueIden) {
      return compactFieldValueIden;
    } else {
      return fieldData[field.compact_field_id];
    }
  };

  let initialCompactFieldOptions = [];
  if (getCompactFieldValue()) {
    initialCompactFieldOptions = [{ RecordID: fieldData[field.compact_field_id], Name: getCompactFieldValue() }];
  }

  // State for compact field options
  const [compactFieldOptions, setCompactFieldOptions] = useState(initialCompactFieldOptions);

  useEffect(() => {
    setCompactFieldOptions([...initialCompactFieldOptions]);
    setOptions([...initialOptionsValue]);
    setOptionsForSelector([...initialOptionsValueForSelector]);
  }, [fieldData]);

  const [compactSearch, setCompactSearch] = useState();
  const debouncedCompactSearch = useDebounce(compactSearch, 350);

  useEffect(() => {
    if (debouncedCompactSearch) {
      // if (parseInt(debouncedCompactSearch.toString().length) > parseFloat(compactField.ajax_search)) {
      const dependent = field.dependent ? form.getFieldValue(field.dependent) : null;
      // console.log(debouncedCompactSearch,jsonParam)
      getsearchFieldData(windowDefinition.ad_window_id, recordId, headerTabId, field.compact_field_id, debouncedCompactSearch, dependent, jsonParam).then((serchDataResponse) => {
        const searchData = JSON.parse(serchDataResponse?.data?.data?.searchField)?.searchData;
        setCompactFieldOptions(searchData);
      });
      // }
    }
  }, [debouncedCompactSearch]);

  const onSearchCompact = (searchText) => {
    setCompactSearch(searchText);
  };

  const [searchKey, setSearchkey] = useState();
  const debouncedSearchKey = useDebounce(searchKey, 350);

  useEffect(() => {
    if (debouncedSearchKey) {
      // console.log(debouncedSearchKey,jsonParam)
      const fieldsForAutoCompleteData = form.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        headerTab.fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${fieldData[field.dependent]}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });

      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);
      if (parseInt(debouncedSearchKey.toString().length) > parseFloat(field.ajax_search)) {
        const dependent = field.dependent ? form.getFieldValue(field.dependent) : null;
        getsearchFieldData(windowDefinition.ad_window_id, recordId, headerTabId, field.ad_field_id, debouncedSearchKey, dependent, allFieldsData).then((serchDataResponse) => {
          const searchData = JSON.parse(serchDataResponse?.data?.data?.searchField)?.searchData;
          // console.log(searchData)
          setOptions(searchData);
        });
      }
    }
  }, [debouncedSearchKey]);

  const onSearch = (searchText) => {
    setSearchkey(searchText);
  };

  const onSearchForSelector = (searchText) => {
    const searchTextValue = searchText.target.value;
    if (searchTextValue) {
      getProcessParamComboFill(field.nt_process_id, field.parameter_id, recordId).then((serchDataResponse) => {
        const searchData = JSON.parse(serchDataResponse?.data?.data?.processParamComboFill);
        setOptionsForSelector(searchData);
      });
    }
  };

  const focusSearchForSelector = (searchText) => {
    // if (searchText.target.value === "") {
    getProcessParamComboFill(field.nt_process_id, field.parameter_id, recordId).then((serchDataResponse) => {
      const searchData = JSON.parse(serchDataResponse?.data.data.processParamComboFill);
      setOptionsForSelector(searchData);
    });
    // }
  };

  const focusSearch = (searchText) => {
    if (searchText.target.value === "") {
      // const dependent = field.dependent ? fieldData[field.dependent] : null;
      const dependent = field.dependent ? form.getFieldValue(field.dependent) : null;
      // console.log(dependent)
      const fieldsForAutoCompleteData = form.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        headerTab.fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${fieldData[field.dependent]}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });

      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);
      getsearchFieldData(windowDefinition.ad_window_id, recordId, headerTabId, field.ad_field_id, searchText.target.value, dependent, allFieldsData)
        .then((searchDataResponse) => {
          try {
            const searchResponseData = JSON.parse(searchDataResponse?.data?.data?.searchField);

            if (searchResponseData && searchResponseData.searchData) {
              const searchData = searchResponseData.searchData;
              setOptions(searchData);
            } else {
              // Handle the case when the response is not as expected
              console.error("Invalid or unexpected response data:", searchDataResponse);
            }
          } catch (error) {
            // Handle JSON parsing errors
            console.error("Error parsing JSON:", error);
          }
        })
        .catch((error) => {
          // Handle any other errors that may occur during the request
          console.error("Request error:", error);
        });

    }
  };

  const focusSearchTags = (searchText) => {
    if (searchText.target.value === "") {
      // const dependent = field.dependent ? fieldData[field.dependent] : null;
      const dependent = field.dependent ? form.getFieldValue(field.dependent) : null;
      // console.log(dependent)
      const fieldsForAutoCompleteData = form.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        headerTab.fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${fieldData[field.dependent]}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });

      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);
      getsearchFieldData(windowDefinition.ad_window_id, recordId, headerTabId, field.ad_field_id, searchText.target.value, dependent, allFieldsData)
        .then((searchDataResponse) => {
          try {
            const searchResponseData = JSON.parse(searchDataResponse?.data?.data?.searchField);

            if (searchResponseData && searchResponseData.searchData) {
              const searchData = searchResponseData.searchData;
              setOptionsForTags(searchData);
            } else {
              // Handle the case when the response is not as expected
              console.error("Invalid or unexpected response data:", searchDataResponse);
            }
          } catch (error) {
            // Handle JSON parsing errors
            console.error("Error parsing JSON:", error);
          }
        })
        .catch((error) => {
          // Handle any other errors that may occur during the request
          console.error("Request error:", error);
        });

    }
  };


  const focusSearchCompactField = (searchText, fieldId) => {
    if (searchText.target.value === "") {
      // const dependent = field.dependent ? fieldData[field.dependent] : null;
      const dependent = field.dependent ? form.getFieldValue(field.dependent) : null;

      const fieldsForAutoCompleteData = form.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        headerTab.fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${fieldData[field.dependent]}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });

      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);
      getsearchFieldData(windowDefinition.ad_window_id, recordId, headerTabId, fieldId ? fieldId : field.ad_field_id, searchText.target.value, dependent, allFieldsData)
        .then((searchDataResponse) => {
          try {
            const searchResponseData = JSON.parse(searchDataResponse?.data?.data?.searchField);

            if (searchResponseData && searchResponseData.searchData) {
              const searchData = searchResponseData.searchData;
              setCompactFieldOptions(searchData);
            } else {
              // Handle the case when the response is not as expected
              console.error("Invalid or unexpected response data:", searchDataResponse);
            }
          } catch (error) {
            // Handle JSON parsing errors
            console.error("Error parsing JSON:", error);
          }
        })
        .catch((error) => {
          // Handle any other errors that may occur during the request
          console.error("Request error:", error);
        });

    }
  };

  const checkAutoComplete = (value) => {
    // console.log(value)
    if (field.enableautocomplete === "Y") {
      setLoading(true);
      const fieldsForAutoCompleteData = form.getFieldsValue(true);
      const fieldsForAutoComplete = { ...fieldsForAutoCompleteData };
      Object.entries(fieldsForAutoComplete).map(([key, val]) => {
        headerTab.fields.map((dat) => {
          if (dat.ad_field_id === key) {
            let keyValueField = val;

            if (keyValueField === true) {
              keyValueField = "Y";
            }
            if (keyValueField === false) {
              keyValueField = "N";
            }
            // if (typeof keyValueField === "number") {
            //   keyValueField = `${value}`;
            // }
            if (dayjs.isDayjs(keyValueField)) {
              keyValueField = `${keyValueField.format("YYYY-MM-DD HH:mm:ss")}`;
            }
            if (keyValueField === "") {
              keyValueField = null;
            }
            if (keyValueField === undefined) {
              keyValueField = null;
            }
            fieldsForAutoComplete[dat.column_name] = keyValueField;
            delete fieldsForAutoComplete[key];
          }
          return null;
        });
        return null;
      });
      const stringifiedFields = JSON.stringify(fieldsForAutoComplete);
      const updatedStrings = stringifiedFields.replace(/\\"/g, '\\"');
      const allFieldsData = JSON.stringify(updatedStrings);

      getAutoCompleteData(windowDefinition.ad_window_id, field.ad_field_id, value, headerTabId, parentId, allFieldsData).then((autoCompleteData) => {
        if (autoCompleteData !== null) {
          setDataOptions(autoCompleteData);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (autoCompleteHiddenData === undefined || !Array.isArray(autoCompleteHiddenData)) {
      // Handle the case where autoCompleteHiddenData is not yet defined or not an array
      return;
    }

    let autocompleteDataOptions = [];
    if (dataOptions !== undefined) {
      Object.entries(dataOptions).map(([dataKey, dataValue]) => {
        const dataValueData2 = dataValue.value;
        let dataValKey = null;
        let dataValName = null;
        if (dataValueData2) {
          dataValKey = dataValueData2[0].RecordID ? dataValueData2[0].RecordID : dataValueData2;
          dataValName = dataValueData2[0].Name ? dataValueData2[0].Name : dataValueData2;
        }
        autocompleteDataOptions.push({
          "dataKey": dataKey,
          'dataValKey': dataValKey === undefined || dataValKey === null ? null : dataValKey,
          'dataValName': dataValName === undefined || dataValName === null ? null : dataValName,
        });
      });
    }

    // Use filter to create a new array without modifying the original
    const newautocompleteData = (autoCompleteHiddenData || []).filter((element) => {
      return !autocompleteDataOptions.some(option => option.dataKey === element.dataKey);
    });

    setAutoCompleteHiddenData([...newautocompleteData, ...autocompleteDataOptions]);
  }, [dataOptions]);

  useEffect(() => {
    if (dataOptions !== undefined) {
      Object.entries(dataOptions).map(([dataKey, dataValue]) => {
        if (dataKey === field.ad_field_id) {
          const dataValueData = dataValue.value;
          const fieldsToUpdate = {};
          if (dataValueData) {
            setOptions(dataValueData);
            fieldsToUpdate[field.ad_field_id] = dataValueData[0].RecordID ? dataValueData[0].RecordID : dataValueData;
          } else {
            setOptions([]);
            fieldsToUpdate[field.ad_field_id] = null;
          }

          Object.entries(fieldsToUpdate).map(([key, val]) => {
            // Check if the value is a string and not just a number
            if (typeof val === 'string' && isNaN(val)) {
              // Attempt to parse the string as a date
              const parsedDate = dayjs(val);
              // Check if the parsed value is a valid date
              if (parsedDate.isValid()) {
                // If it's a valid date, assign it to the field
                fieldsToUpdate[key] = parsedDate;
              }
            }
          });
          // console.log(fieldsToUpdate)
          form.setFieldsValue(fieldsToUpdate);
        }
        return null;
      });
    }
  }, [dataOptions]);


  const formatJson = (e) => {
    let prettyJson = e.target.value;
    try {
      prettyJson = JSON.stringify(JSON.parse(e.target.value), null, 4);
    } catch (err) {
      prettyJson = e.target.value;
    }
    const fieldsToUpdate = {};
    fieldsToUpdate[field.ad_field_id] = prettyJson;
    form.setFieldsValue(fieldsToUpdate);
  };

  const [fieldInput, setFieldInput] = useState();
  const fieldInputKey = useDebounce(fieldInput, 350);

  useEffect(() => {
    if (fieldInputKey) {
      checkAutoComplete(fieldInputKey);
    }
  }, [fieldInputKey]);

  const checkAutoFillData = (e) => {
    const inputValue = e.target.value;
    let finalValue = inputValue;
    if (field.uom_field_id) {
      const userPreferencesData = JSON.parse(localStorage.getItem("userPreferences"));
      const uomFields = JSON.parse(localStorage.getItem("globalParameters")).UOM;
      const formValues = form.getFieldsValue();
      const uom_field_id = formValues[field.uom_field_id];
      const uom = uomFields.find((item) => Object.keys(item)[0] === uom_field_id);
      if (uom) {
        const uomDetails = uom[uom_field_id];
        // Get precision from uomDetails or userPreferencesData
        const standardPrecision = uomDetails?.standardPrecision ?? userPreferencesData?.decimalPlaces;
      
        const parsedValue = parseFloat(inputValue);
        if (!isNaN(parsedValue)) {
          // Only round off if standardPrecision is explicitly defined
          const finalValue = standardPrecision !== undefined && standardPrecision !== null 
            ? parsedValue.toFixed(standardPrecision) 
            : parsedValue;
      
          // Update the form with the updated or original value
          const fieldsToUpdate = {};
          fieldsToUpdate[field.ad_field_id] = finalValue;
          form.setFieldsValue(fieldsToUpdate);
        }
      }
      
    }
    if (field.enableautocomplete === "Y") {
      setFieldInput(finalValue); 
    } 
  };

  const onAmountChange = (e) => {
    let inputValue = e.target.value;
  
    // Retrieve user preferences and currency settings
    const userPreferencesData = JSON.parse(localStorage.getItem("userPreferences"));
    const currFields = JSON.parse(localStorage.getItem("globalParameters")).Currency;
    const parentTab = windowDefinition.tabs.find((tab) => tab.tablevel === "0");
  
    const currencyFieldIdInTab = headerTab?.currency_field_id
      ? headerTab.currency_field_id
      : parentTab?.currency_field_id || userPreferencesData?.Currency;
  
    const currencyField =
      headerTab?.currency_field_id === currencyFieldIdInTab
        ? fieldData[currencyFieldIdInTab]
        : parentTab?.currency_field_id === currencyFieldIdInTab
        ? parentTabData[currencyFieldIdInTab]
        : null;
  
    const currField = currFields.find((item) => Object.keys(item)[0] === currencyField);
    if (currField) {
      const uomDetails = currField[currencyField];
      const standardPrecision = uomDetails?.pricePrecision ?? userPreferencesData?.decimalPlaces;
      const parsedValue = parseFloat(inputValue);
      if (!isNaN(parsedValue)) {
        if (standardPrecision !== undefined && standardPrecision !== null) {
          // Round the value to the specified precision
          inputValue = parsedValue.toFixed(standardPrecision);
        } else {
          // Do not round the value if precision is not available
          inputValue = parsedValue;
        }    
      }
    }
    const fieldsToUpdate = {};
    fieldsToUpdate[field.ad_field_id] = inputValue;
    form.setFieldsValue(fieldsToUpdate);
  };

  
    

  const getTimePeriod = () => {
    const dateValue = getFieldValue();
    const valueDate = dateValue ? dayjs(dateValue) : null;
    return valueDate;
  };

  const openAddNewRecordPopup = () => {
    window.open(`/popupWindow/${field.new_record_window}/NEW_RECORD`, "New Record Window", "width=1200,height=600,left=210,top=120");
  };

  const quillRef = useRef(null);
  const imageHandler = () => {
    const range = quillRef.current.getEditorSelection();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      setLoading(true);
      const file = input.files[0];
      const formData = new FormData();
      formData.set("image", file, file.filename);
      try {
        const response = await axios({
          method: "POST",
          url: "https://sapp.mycw.in/image-manager/uploadImage",
          data: formData,
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
            APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg",
          },
        });
        if (!response) {
          return null;
        } else {
          quillRef.current.getEditor().insertEmbed(range.index, "image", response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  };

  const imageUploadStatusChange = (uploadStatus) => {
    if (uploadStatus.file.percent === 100 || uploadStatus.file.status) {
      const fieldsToUpdate = {};
      fieldsToUpdate[field.ad_field_id] = uploadStatus.file.response;
      form.setFieldsValue(fieldsToUpdate);
    }
  };

  const handleFileUpload = async ({ file, onSuccess, onError }) => {
    try {
      const genericUrl = process.env.REACT_APP_genericUrl;
      const { access_token } = getOAuthHeaders();
      let newUrl = genericUrl.replace("graphql", "api");
      let appId = localStorage.getItem("appId");

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "data",
        JSON.stringify({
          windowId,
          tabId: headerTabId,
          recordId: props.recordId || recordId,
        })
      );

      const response = await axios.post(`${newUrl}/uploadImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          appId: appId,
          Authorization: `Bearer ${access_token}`,
        },
      });
      
      if (response.data.messageCode === "200") {
        // setImageUrl(response.data.data)
        const fieldsToUpdate = {};
        fieldsToUpdate[field.ad_field_id] = response.data.data;
        form.setFieldsValue(fieldsToUpdate);
        notification.success({
          message: response.data.message,
        });
        onSuccess(`${response.data.data}`);
        // setImageModal(false)
      } else {
        notification.info({
          message: response.data.message,
        });
        onError(new Error(response.data.message));
      }

    } catch (error) {

      console.error(error);
      notification.error({
        message: "File upload failed",
        description: error.message,
      });
      onError(error);
    }
  };

  const reactQuillModules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [false, "serif", "monospace"] }, { header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        ["code", "background"],
        ["code-block", "direction"],
        ["link", "image", "video"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
      }
    }
  }), []);

  const fousElementRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (fousElementRef.current) {
        fousElementRef.current.focus();
      }
    }, 100);
  }, [fieldData]);


  const JSONEditorWithModal = ({ value, onChange, isReadOnly, fieldName }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [jsonValue, setJsonValue] = useState('');
    const [parsedValue, setParsedValue] = useState({});


    useEffect(() => {
      try {
        const parsed = typeof value === 'string' ? JSON.parse(value) : value;
        setParsedValue(parsed);
        setJsonValue(JSON.stringify(parsed, null, 2));
      } catch (error) {
        console.error('Invalid JSON:', error);
      }
    }, [value]);

    const showModal = (e) => {
      e.stopPropagation();
      setIsModalVisible(true);
    };

    const handleOk = () => {
      if (activeTab === '2') {
        try {
          const parsed = JSON.parse(jsonValue);
          onChange(parsed);
          setParsedValue(parsed);
         
        } catch (error) {
          console.error('Invalid JSON:', error);
        }
      }
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const parseJSON = (obj, parentKey = '', level = 0) => {
      if (!obj || typeof obj !== 'object') return [];

      return Object.entries(obj).flatMap(([key, value]) => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (Array.isArray(value)) {
          const arrayRows = [
            {
              key: currentKey,
              fieldName: key,
              value: `Array (${value.length} items)`,
              level,
              isParent: true
            }
          ];

          value.forEach((item, index) => {
            if (typeof item === 'object' && item !== null) {
              arrayRows.push(...parseJSON(item, `${currentKey}[${index}]`, level + 1));
            } else {
              arrayRows.push({
                key: `${currentKey}[${index}]`,
                fieldName: `[${index}]`,
                value: JSON.stringify(item),
                level: level + 1
              });
            }
          });

          return arrayRows;
        }

        if (typeof value === 'object' && value !== null) {
          return [
            {
              key: currentKey,
              fieldName: key,
              value: 'Object',
              level,
              isParent: true
            },
            ...parseJSON(value, currentKey, level + 1)
          ];
        }

        return [{
          key: currentKey,
          fieldName: key,
          value: JSON.stringify(value),
          level
        }];
      });
    };

    const columns = [
      {
        title: 'Field',
        dataIndex: 'fieldName',
        key: 'fieldName',
        width: 150,
        ellipsis: true,
        render: (text, record) => (
          <Text style={{
            paddingLeft: `${record.level * 20}px`,
            fontWeight: record.isParent ? 500 : 'normal',
            fontStyle: record.isParent ? "italic" : "normal",
            color: record.isParent ? 'green' : 'rgba(0, 0, 0, 0.85)'
          }}>
            {text}
          </Text>
        )
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        ellipsie: true,
        render: (text, record) => (
          <Text style={{
            fontStyle: record.isParent ? "italic" : "normal",
            color: record.isParent ? 'green' : 'rgba(0, 0, 0, 0.85)',
            fontWeight: record.isParent ? 500 : 'normal'
          }}>
            {text}
          </Text>
        )
      }
    ];

    const tableData = parseJSON(parsedValue);

    return (
      <div className="json-editor-wrapper">
        <div className="table-wrapper">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: "40vh", x: "100%" }}
            size="small"
            bordered
            rowKey="key"
          />
          <div className="edit-button-wrapper">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={showModal}
              className="edit-button"
            />
          </div>
        </div>

        <Modal
          title={<>
            <center style={{ fontWeight: 'bold', width: '40%px', fontStyle: 'normal', fontSize: "24px", textAlign: "start" }}>Edit {fieldName}</center></>}
          style={{ top: "-17px" }}
          // closable={false}
          className="tabModal"
          centered
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
          maskClosable={false}
          closeIcon={<img src={Close} alt="" style={{ height: "4vh" }} />}
          bodyStyle={{ paddingTop: 0 }}
          okText={
            <span style={{ color: "white", width: "88px", height: "36px", fontWeight: 600, borderRadius: '6px' }}>Submit</span>
          }
          cancelText={
            <span style={{ width: "88px", height: "36px", fontWeight: 600, borderRadius: '6px' }}>Cancel</span>
          }
          footer={activeTab === "2" ? undefined : null} 
        >
          <Tabs activeKey={activeTab} onChange={key => {
            setActiveTab(key);

          }}>
            <TabPane tab="Table" key="1">
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                bordered
                size="small"
                rowKey="key"
                scroll={{ y: "60vh", x: "100%" }}
              />
            </TabPane>
            <TabPane tab="JSON" key="2">
              <Input.TextArea
                value={jsonValue}
                onChange={(e) => setJsonValue(e.target.value)}
                autoSize={{ minRows: 10, maxRows: 20 }}
                disabled={isReadOnly}
                style={{ fontFamily: 'monospace' }}
              />
            </TabPane>
          </Tabs>
        </Modal>

        <style jsx>{`
          .json-editor-wrapper {
            position: relative;
            
          }
  
          .table-wrapper {
            position: relative;
            background: #ffffff;
          }
  
          .table-wrapper:hover .edit-button-wrapper {
            opacity: 1;
          }
  
          .edit-button-wrapper {
            position: absolute;
            top: 8px;
            right: 8px;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            z-index: 1;
          }
  
          .edit-button {
            color: #666;
            background: rgba(255, 255, 255, 0.8);
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            padding: 4px 8px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
  
          .edit-button:hover {
            background: #fff;
            color: #1890ff;
            border-color: #1890ff;
          }
        `}</style>
      </div>
    );
  };

  const ImageUploadWithModal = ({
    field,
    getFieldValue,
    handleFileUpload,
    imageUploadStatusChange,
  }) => {
    const [imageModal, setImageModal] = useState(false);
    const [imageUrl, setImageUrl] = useState(getFieldValue() || ""); // Main state
    const [urlInput, setUrlInput] = useState(getFieldValue() || ""); // Temporary state for input
    const [actkey, setActKey] = useState("1"); // Active tab state
  
    const showModal = () => {
      setUrlInput(imageUrl); // Sync urlInput with imageUrl when modal opens
      setImageModal(true);
    };
  
    const handleCancel = () => {
      setImageModal(false);
      setActKey("1"); // Reset tab to default
    };
  
    const handleOk = () => {
      if (actkey === "2" && (!urlInput || !isValidUrl(urlInput))) {
        message.error("Please enter a valid image URL.");
        return;
      }
      setImageUrl(urlInput); // Update the main imageUrl state
      const fieldsToUpdate = {};
      fieldsToUpdate[field.ad_field_id] = urlInput;
      form?.setFieldsValue(fieldsToUpdate);
      setImageModal(false); // Close the modal
      message.success("Image updated successfully!");
    };
  
    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };

    const onFileUpload = (file) => {
      const uploadedImageUrl = file.response || ""; // Extract URL from response
      if (uploadedImageUrl) {
        setImageUrl(uploadedImageUrl);
        setImageModal(false);
      }
    };
  
  
    return (
      <>
        <Row style={{ alignItems: "center" }}>
          {imageUrl ? (
            <div style={{ position: "relative", display: "inline-block" }}>
              <img
                src={imageUrl}
                alt="Uploaded"
                width={300}
                height={100}
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: 4,
                  objectFit: "cover",
                }}
              />
              <Button
                onClick={showModal}
                icon={<EditOutlined />}
                style={{
                  position: "absolute",
                  top: 4,
                  right: 4,
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                  border: "none",
                  padding: 2,
                  height: 24,
                  width: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          ) : (
            // <Upload
            //   customRequest={handleFileUpload}
            //   listType="picture"
            //   headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
            //   name="image"
            //   onChange={(info) => {
            //     imageUploadStatusChange(info); // Keep existing behavior
            //     if (info.file.status === "done") {
            //       onFileUpload(info.file); // Update imageUrl state and form field
            //     }
            //   }}

            //   maxCount={1}
            // >
              <Button icon={<EditOutlined />} onClick={showModal}>Add Image</Button>
            // </Upload>
          )}
        </Row>
        <Modal
         title={<>
        <center style={{ fontWeight: 'bold', width: '40%px', fontStyle: 'normal', fontSize: "24px", textAlign: "start" }}>Edit {field.name}</center></>}
        style={{ top: "-17px" }}
        // closable={false}
        className="tabModal"
        centered
          visible={imageModal}
          onOk={handleOk}
          onCancel={handleCancel}
          width={600}
          maskClosable={false}
          closeIcon={<img src={Close} alt="" style={{ height: "4vh" }} />}
          bodyStyle={{ paddingTop: 0 }}
          okText={
            <span style={{ color: "white", width: "88px", height: "36px", fontWeight: 600, borderRadius: '6px' }}>Submit</span>
          }
          cancelText={
            <span style={{ width: "88px", height: "36px", fontWeight: 600, borderRadius: '6px' }}>Cancel</span>
          }
          footer={actkey==="2"?undefined:null} 
        >
          <Tabs activeKey={actkey} onChange={(key) => setActKey(key)}>
            <TabPane tab="Upload" key="1">
              <Upload
                customRequest={handleFileUpload}
                listType="picture"
                headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                name="image"
                onChange={(info) => {
                  imageUploadStatusChange(info); // Keep existing behavior
                  if (info.file.status === "done") {
                    onFileUpload(info.file); // Update imageUrl state and form field
                  }
                }}
  
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </TabPane>
            <TabPane tab="URL" key="2">
              <Input
                placeholder="Enter image URL"
                value={urlInput} // Use temporary state
                onChange={(e) => setUrlInput(e.target.value)} // Update urlInput
              />
            </TabPane>
          </Tabs>
        </Modal>
      </>
    );
  };
  
  
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  const initializeSpeechRecognition = (id) => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert('Speech recognition is not supported in this browser.');
      return null;
    }

    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;

      // Get the current value of the field
      const currentFieldValue = form.getFieldValue(id) || ""; // Default to empty string if null/undefined

      // Append the new transcript to the existing value
      const updatedValue = `${currentFieldValue} ${transcript}`.trim();

      // Update the form field value with the appended text
      form.setFieldsValue({ [id]: updatedValue });
    };

    return recognition;

  };

  const [fieldValues, setFieldValues] = React.useState({});
  const fieldRefs = React.useRef({});

  const handleInputChange = (fieldId, value) => {
    console.log(fieldId,value)
    form.setFieldsValue({ [fieldId]: value });
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldId]: value, // Update only the specific field value
    }));
  };

  const handleMicClick = (fieldId) => {
    if (!recognitionRef.current) {
      recognitionRef.current = initializeSpeechRecognition(fieldId);
    }

    if (recognitionRef.current) {
      // If the mic is already listening, stop it and append the value
      if (isListening) {
        // Stop recognition manually when mic button is clicked again
        recognitionRef.current.stop();
        setIsListening(false); // Set to false when mic stops listening
      } else {
        // Start listening for speech input
        recognitionRef.current.start();
        setIsListening(true); // Set to true when mic starts listening
      }

      // Listen for the 'onresult' event to get the speech recognition result
      recognitionRef.current.onresult = (event) => {
        const micInput = event.results[0][0].transcript; // Get the recognized text
        console.log(`Recognized text: ${micInput}`); // Debugging output

        // Get the current value of the field (before appending)
        const currentFieldValue = fieldValues[fieldId] || form.getFieldValue(fieldId) || ""; // Default to empty string if null/undefined

        // Append the recognized text to the existing value (if any)
        const updatedValue = `${currentFieldValue} ${micInput}`.trim();

        // Update the field value with the appended text
        setFieldValues((prevValues) => ({
          ...prevValues,
          [fieldId]: updatedValue,
        }));

        // Optionally, update the form field value as well (if using Ant Design's Form)
        form.setFieldsValue({ [fieldId]: updatedValue });

        // Stop recognition after the result
        recognitionRef.current.stop();
        setIsListening(false); // Set to false once the recognition ends
      };

      // Handle recognition errors
      recognitionRef.current.onerror = (error) => {
        console.error(`Speech recognition error: ${error.error}`);
        setIsListening(false); // Ensure that isListening is set to false on error
      };

      // Stop listening once recognition ends (e.g., user stops talking)
      recognitionRef.current.onend = () => {
        setIsListening(false); // Set to false when speech recognition ends
        console.log("Speech recognition ended.");
      };
    }
  };
  // console.log(fieldData,"--->")
  return (
    <Fragment>
      {(() => {
        switch (field.nt_base_reference_id) {
          case FieldReference.String:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Input style={{ borderRadius: "5px" }} onChange={checkAutoFillData} ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} />
              </Form.Item>
            );
          case FieldReference.TableDir:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={fieldData[field.ad_field_id]}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Select
                  className="formView"
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  onSearch={onSearch}
                  onFocus={focusSearch}
                  onChange={checkAutoComplete}
                  onClear={checkAutoComplete}
                  optionFilterProp="children"
                  allowClear={true}
                  filterOption={false}
                  disabled={field.isreadonly === "Y" ? true : false}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {field.create_new_record === "Y" && field.new_record_window ? (
                        <div onClick={openAddNewRecordPopup} style={{ padding: "4px", borderTop: "1px solid #e6e6e6", textAlign: "center", cursor: "pointer" }}>
                          <PlusOutlined /> Add New {field.name}
                        </div>
                      ) : null}
                    </div>
                  )}
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                >
                  {options.map((option, index) => {
                    // console.log(option)
                    let url;

                    const parts = option.Name.split(' - ', 3); // Split the option.Name to check for URL and name
                    if (parts[0].startsWith('http://') || parts[0].startsWith('https://')) {
                      url = parts[0]; // Use the third part if it exists, otherwise use the second part
                    }
                    // const url = parts.length > 1 ? parts[0] : null; // URL exists if the split results in more than one part
                    const name = parts.length > 1 ? parts[2] : option.Name; // Use the second part as the name, or the whole option.Name if no URL
                    // console.log(parts)
                    return (
                      <Option key={`${index}-${option.RecordID}`} value={option.RecordID}>
                        {url ? <img src={url} alt={name} style={{ width: '20px', marginRight: '10px' }} /> : null}
                        {url ? name : option.Name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          case FieldReference.TagsTableDir:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={fieldData[field.ad_field_id] ? JSON.parse(fieldData[field.ad_field_id]) : undefined}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Select
                  className="formView"
                  mode="tags"
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  onSearch={onSearch}
                  onFocus={focusSearchTags}
                  onChange={checkAutoComplete}
                  onClear={checkAutoComplete}
                  optionFilterProp="children"
                  allowClear={true}
                  filterOption={false}
                  disabled={field.isreadonly === "Y" ? true : false}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {field.create_new_record === "Y" && field.new_record_window ? (
                        <div onClick={openAddNewRecordPopup} style={{ padding: "4px", borderTop: "1px solid #e6e6e6", textAlign: "center", cursor: "pointer" }}>
                          <PlusOutlined /> Add New {field.name}
                        </div>
                      ) : null}
                    </div>
                  )}
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                >
                  {optionsForTags.map((option, index) => {

                    return (
                      <Option key={`${index}-${option.RecordID}`} value={option.RecordID}>
                        {option.Name}
                        {/* {url ? <img src={url} alt={name} style={{ width: '20px', marginRight: '10px' }} /> : null} */}
                        {/* {url ? name:option.Name} */}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          case FieldReference.Tags:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={fieldData[field.ad_field_id] ? JSON.parse(fieldData[field.ad_field_id]) : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Select
                  className="formView"
                  mode="tags"
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  onSearch={onSearch}
                  onFocus={focusSearchTags}
                  onChange={checkAutoComplete}
                  onClear={checkAutoComplete}
                  optionFilterProp="children"
                  allowClear={true}
                  filterOption={false}
                  disabled={field.isreadonly === "Y" ? true : false}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {field.create_new_record === "Y" && field.new_record_window ? (
                        <div onClick={openAddNewRecordPopup} style={{ padding: "4px", borderTop: "1px solid #e6e6e6", textAlign: "center", cursor: "pointer" }}>
                          <PlusOutlined /> Add New {field.name}
                        </div>
                      ) : null}
                    </div>
                  )}
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                >
                  {optionsForTags.map((option, index) => {

                    return (
                      <Option key={`${index}-${option.RecordID}`} value={option.RecordID}>
                        {option.Name}
                        {/* {url ? <img src={url} alt={name} style={{ width: '20px', marginRight: '10px' }} /> : null} */}
                        {/* {url ? name:option.Name} */}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          case "Selector":
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={fieldData[field.ad_field_id]}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
              >
                <Select
                  className="formView"
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  onInputKeyDown={onSearchForSelector}
                  onFocus={focusSearchForSelector}
                  onChange={checkAutoComplete}
                  optionFilterProp="children"
                  allowClear={true}
                  disabled={field.isreadonly === "Y" ? true : false}
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                >
                  {optionsForSelector.map((option, index) => (
                    <Option key={`${index}-${option.name}`} value={option.recordid}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );
          case FieldReference.List:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
              >
                <Select
                  className="formView"
                  disabled={field.isreadonly === "Y" ? true : false}
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  placeholder={`Select ${field.name}`}
                  optionFilterProp="children"
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                  allowClear
                >
                  {field.Values.map((option, index) => (
                    <Option key={`${index}-${option.value}`} value={option.key}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );
          case FieldReference.TagsList:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={initialOptionsForTagsList() || undefined}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
              >
                <Select
                  className="formView"
                  mode="tags"
                  disabled={field.isreadonly === "Y" ? true : false}
                  showSearch
                  style={{ width: "100%", borderRadius: "5px" }}
                  placeholder={`Select ${field.name}`}
                  optionFilterProp="children"
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                >
                  {field.Values.map((option, index) => (
                    <Option key={`${index}-${option.value}`} value={option.key}>
                      {option.value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            );

          case "42434B24C34E49B3B2822981FFBEAB03":

            const compactField = fields.filter(feld => feld.ad_field_id === field.compact_field_id);

            // Define the prefix selector
            const prefixSelector = (
              <Form.Item name={field.compact_field_id} initialValue={getCompactFieldValue(field.compact_field_id)} noStyle>
                {compactField[0].nt_base_reference_id === "17" ?
                  <Select
                    className="formView"
                    disabled={field.isreadonly === "Y" ? true : false}
                    showSearch
                    style={{
                      width: 70,
                    }}
                    placeholder={`Select ${compactField[0].name}`}
                    optionFilterProp="children"
                    ref={formFieldIndexKey === 0 ? fousElementRef : null}
                  >
                    {compactField[0].Values.map((option, index) => (
                      <Option key={`${index}-${option.value}`} value={option.key}>
                        {option.value}
                      </Option>
                    ))}
                  </Select> :

                  <Select
                    className="formView"
                    showSearch
                    style={{
                      width: 70,
                    }}
                    onSearch={onSearchCompact}
                    onFocus={(searchText) => focusSearchCompactField(searchText, field.compact_field_id)}
                    onChange={checkAutoComplete}
                    onClear={checkAutoComplete}
                    optionFilterProp="children"
                    allowClear={true}
                    filterOption={false}
                    disabled={field.isreadonly === "Y" ? true : false}
                    dropdownRender={(menu) => (
                      <div>
                        {menu}
                        {field.create_new_record === "Y" && field.new_record_window ? (
                          <div onClick={openAddNewRecordPopup} style={{ padding: "4px", borderTop: "1px solid #e6e6e6", textAlign: "center", cursor: "pointer" }}>
                            <PlusOutlined /> Add New {field.name}
                          </div>
                        ) : null}
                      </div>
                    )}
                    ref={formFieldIndexKey === 0 ? fousElementRef : null}
                  >
                    {compactFieldOptions.map((option, index) => (
                      <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                        {option.Name}
                      </Option>
                    ))}
                  </Select>}
              </Form.Item>
            );

            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue()}
                rules={[
                  {
                    required: field.ismandatory === "Y",
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ borderRadius: "5px", width: "100%" }}
                  onChange={checkAutoFillData}
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                  disabled={field.isreadonly === "Y"}
                />
              </Form.Item>
            );

          case FieldReference.YesNo:
            return (
              <Fragment>
                {getFieldValue() ? (
                  <Form.Item
                    style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                    label={<span style={{ fontWeight: 600, fontSize: 12, fontFamily: "Inter" }}>{field.name}</span>}
                    name={field.ad_field_id}
                    initialValue={getFieldValue().trim() === "Y" ? true : false}
                    valuePropName="checked"
                  >
                    <Checkbox ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} />
                  </Form.Item>
                ) : null}
              </Fragment>
            );
          case FieldReference.Integer:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue() ? parseFloat(getFieldValue()) : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input proper ${field.name} value`,
                  },
                  {
                    validator: async (_, value) => {
                      try {
                        if (value) {
                          const intValue = value.toString();
                          if (!(intValue.indexOf(".") === -1 && intValue.length <= parseInt(field.displaylength))) {
                            throw new Error("Invalid Format");
                          }
                          if (isNaN(value)) {
                            throw new Error("Not a Number");
                          }
                        }
                      } catch (error) {
                        return Promise.reject(new Error("Invalid Integer"));
                      }
                    },

                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Input onChange={checkAutoFillData} ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} />
              </Form.Item>
            );
          case FieldReference.Number:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                // initialValue={getFieldValue() ? parseFloat(getFieldValue()) : null}
                initialValue={fieldData[field.ad_field_id]}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                  {
                    validator: async (_, value) => {
                      if (value) {
                        try {
                          const intValue = value.toString();
                          if (!(intValue.length <= parseInt(field.displaylength))) {
                            throw new Error("Invalid Format");
                          }
                          if (isNaN(value)) {
                            throw new Error("Not a Number");
                          }
                          if (intValue.length < 1) {
                            throw new Error("Input Value");
                          }
                        } catch (error) {
                          return Promise.reject(new Error("Invalid Integer"));
                        }
                      }
                    },

                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Input style={{ borderRadius: "5px" }} onBlur={checkAutoFillData} ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} />
              </Form.Item>
            );
          case FieldReference.ID:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue() ? getFieldValue() : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name}`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Input disabled={field.isreadonly === "Y" ? true : false} ref={formFieldIndexKey === 0 ? fousElementRef : null} />
              </Form.Item>
            );
          case FieldReference.DateTime:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getTimePeriod()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <DatePicker className="formView" ref={formFieldIndexKey === 0 ? fousElementRef : null} style={{ width: "100%", borderRadius: "5px", height: "1rem" }} showTime={true} format={userPreferences.dateTimeFormat} />
              </Form.Item>
            );
          case FieldReference.Date:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getTimePeriod()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <DatePicker
                  ref={formFieldIndexKey === 0 ? fousElementRef : null}
                  disabled={field.isreadonly === "Y" ? true : false}
                  style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.SelectTag}
                  format={userPreferences.dateFormat}
                />
              </Form.Item>
            );
          case FieldReference.Time:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getTimePeriod()}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <TimePicker ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} use12Hours={true} style={{ width: "100%" }} format={userPreferences.timeFormat} />
              </Form.Item>
            );

          case FieldReference.Password:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue() ? getFieldValue() : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <Password ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} />
              </Form.Item>
            );
          case FieldReference.JSON:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue() ? getFieldValue() : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Invalid ${field.name} Value`,
                  },
                  {
                    validator: async (_, value) => {
                      if (value) {
                        try {
                          JSON.stringify(value);
                        } catch (error) {
                          return Promise.reject(new Error("Invalid JSON"));
                        }
                      }
                    },

                  },
                ]}
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
              >
                <JSONEditorWithModal
                  value={JSON.parse(getFieldValue() || '{}')}
                  fieldName={field.name}
                  isReadOnly={field.isreadonly === 'Y'}
                  onChange={(updatedValue) => {
                    form.setFieldsValue({
                      [field.ad_field_id]: updatedValue
                    });
                  }}
                />
                {/* <TextArea ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} autoSize={{ minRows: 1, maxRows: 6 }} onChange={formatJson} /> */}
              </Form.Item>
            );
          case FieldReference.WYSIWYGEditor:
            return (
              <Form.Item
                label={
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: 12,
                      fontFamily: "Inter",
                      opacity: 0.6,
                    }}
                  >
                    {field.name}
                  </span>
                }
                name={field.ad_field_id}
                initialValue={getFieldValue() ? getFieldValue() : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={
                  Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem
                }
              >
                <div style={{ position: "relative" }}>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    modules={reactQuillModules}
                    disabled={field.isreadonly === "Y" ? true : false}
                    value={fieldValues[field.ad_field_id] || getFieldValue() || ""} // Ensure it's a string
                    onChange={(content) =>
                      handleInputChange(field.ad_field_id, content) // Update state with HTML content
                    }
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleMicClick(field.ad_field_id)} // Toggle mic listening
                  >
                    {isListening && (
                      <div
                        style={{
                          position: "absolute",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 0, 0, 0.2)", // Pulsating background color
                          animation: "pulse-bg 1.5s infinite",
                        }}
                      ></div>
                    )}
                    <div
                      style={{
                        position: "relative",
                        zIndex: 1, // Keep the icon above the pulsating background
                      }}
                    >
                      {/* {isListening ? (
                    <span style={{ fontSize: "12px", fontWeight: "bold", color: "red" }}>
                      Listening
                    </span>
                  ) : ( */}
                      <AudioOutlined style={{ fontSize: "14px", color: "#000" }} />
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </Form.Item>

            );

          case FieldReference.Image:
            return (
              <>
                <Row>
                  <Form.Item label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>} name={field.ad_field_id} initialValue={getFieldValue()}>
                    <Input type="hidden" />
                  </Form.Item>
                </Row>
                <Row style={{ marginTop: "-21px" }}>
                <ImageUploadWithModal
                  field={field}
                  getFieldValue={getFieldValue} // Example initial image
                  handleFileUpload={handleFileUpload}
                 imageUploadStatusChange={imageUploadStatusChange}
                />
                  {/* <Upload
                    customRequest={handleFileUpload}
                    // action="https://sapp.mycw.in/image-manager/uploadImage"
                    listType="picture"
                    headers={{ APIKey: "AUa4koVlpsgR7PZwPVhRdTfUvYsWcjkg" }}
                    name="image"
                    onChange={imageUploadStatusChange}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                </Row>
              </>
            );

          case FieldReference.RichTextArea:
            return (
              <Form.Item
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue() ? getFieldValue() : null}
                rules={[
                  {
                    required: field.ismandatory === "Y" ? true : false,
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={{ width: "100%" }}
              // style={{Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}}
              >
                <TextArea ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false} autoSize={{ minRows: 3, maxRows: 9 }} />
              </Form.Item>
            )

          case FieldReference.Text:
            return (
              <Form.Item
                label={
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: 12,
                      fontFamily: "Inter",
                      opacity: 0.6,
                    }}
                  >
                    {field.name}
                  </span>
                }
                name={field.ad_field_id}
                initialValue={
                  fieldValues[field.ad_field_id] ??
                  getFieldValue(field.ad_field_id) // Get the current value from the form's state
                }
                rules={[
                  {
                    required: field.ismandatory === "Y",
                    message: `Please input ${field.name} with proper value`,
                  },
                ]}
                style={
                  Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField
                    .FormItem
                }
              >
                <div style={{ position: "relative" }}>
                  <TextArea
                    style={{ borderRadius: "5px" }}
                    ref={(el) => {
                      if (el && !fieldRefs.current[field.ad_field_id]) {
                        // Assign ref dynamically for each field
                        fieldRefs.current[field.ad_field_id] = el;
                      }
                    }}
                    disabled={field.isreadonly === "Y"}
                    rows={3}
                    // Use state for value, fallback to ref's value for the initial render
                    value={
                      fieldValues[field.ad_field_id] ??
                      fieldRefs.current[field.ad_field_id]?.value ??
                      getFieldValue()
                    }
                    onChange={(e) => handleInputChange(field.ad_field_id, e.target.value)}
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => handleMicClick(field.ad_field_id)} // Toggle mic listening
                  >
                    {isListening && (
                      <div
                        style={{
                          position: "absolute",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          backgroundColor: "rgba(255, 0, 0, 0.2)", // Pulsating background color
                          animation: "pulse-bg 1.5s infinite",
                        }}
                      ></div>
                    )}
                    <div
                      style={{
                        position: "relative",
                        zIndex: 1, // Keep the icon above the pulsating background
                      }}
                    >
                      <AudioOutlined style={{ fontSize: "14px", color: "#000" }} />
                      
                    </div>
                  </div>

                </div>
              </Form.Item>
            );

            case FieldReference.Amount:
              return (
                <Form.Item
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue()}
              >
                <Input onBlur={onAmountChange} ref={formFieldIndexKey === 0 ? fousElementRef : null} disabled={field.isreadonly === "Y" ? true : false}/>
              </Form.Item>
              );

          default:
            return (
              <Form.Item
                style={Themes.contentWindow.recordWindow.RecordHeader.RecordForm.FormField.FormItem}
                label={<span style={{ fontWeight: 400, fontSize: 12, fontFamily: "Inter", opacity: 0.6 }}>{field.name}</span>}
                name={field.ad_field_id}
                initialValue={getFieldValue()}
              >
                <Input ref={formFieldIndexKey === 0 ? fousElementRef : null} readOnly={true} />
              </Form.Item>
            );
        }
      })()}
    </Fragment>
  );
};

export default FormField;
